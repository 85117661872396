import Ajax from '../js/axios'

const pathUrl = '/exam-service/TestWorker'
// const pathUrl = '/TestWorker'
const pathUrl1 = '/exam-service/addTestRecord'
class testWorkerApi {
    // 登录首页计划列表
    static async getTodayGradingPlan (data) {
        let info = {
            url: pathUrl + '/getTodayGradingPlan',
            type: 'get',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 检查某个任务是否开启
    static async checkIsStart (data) {
        let info = {
            url: pathUrl + '/checkIsStart',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 添加开启任务记录
    static async addPlanOpenRecords (data) {
        let info = {
            url: pathUrl + '/addPlanOpenRecords',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 考试日志填写接口
    static async addCheckLog (data) {
        let info = {
            url: pathUrl + '/addCheckLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 检查单填写
    static async addCheckList (data) {
        let info = {
            url: pathUrl + '/addCheckList',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // getAllOpenUser  
    static async getAllOpenUser (data) {
        let info = {
            url: pathUrl + '/getAllOpenUser',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkIsOpen 
    static async checkIsOpen (data) {
        let info = {
            url: pathUrl + '/checkIsOpen',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    static async getUserAnswer (data) {
        let info = {
            url: pathUrl1 + '/getUserAnswer',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkUserAnswerStatu
    /**
     *
     *
     * @static
     * @param {*} examPlanId   考试计划Id
     * @param {*}userIds    userId集合
     * @returns
     * @memberof testWorkerApi
     */
    static async checkUserAnswerStatu (data) {
        let info = {
            url: pathUrl1 + '/checkUserAnswerStatu',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    //  
    /**
     *
     *
     * @static
     * @param {String} id
     * @returns
     * @memberof testWorkerApi
     */
    static async getCheckListInfo (data) {
        let info = {
            url: pathUrl + '/getCheckListInfo',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkIsHaveSaveLog
    /**
     *
     *
     * @static
     * @param {string} userId 用户id
     * @param {string} logType 日志类型 1、监考 2 固定  3网络 4 现场
     * @param {Number} planType 计划类别  1 考试 2  评卷
     * @param {string}  planId 考试计划/评卷计划ID
     * @returns
     * @memberof testWorkerApi
     */
    static async checkIsHaveSaveLog (data) {
        let info = {
            url: pathUrl + '/checkIsHaveSaveLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // tbCheckLogService
    /**
     *
     *
     * @static
     * @param {*} data
     * @returns
     * @memberof testWorkerApi
     */
    static async tbCheckLogService (data) {
        let info = {
            url: pathUrl + '/tbCheckLogService',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkHasSaveCheckList
    /**
     *
     *
     * @static
     * @param {*} planId 考试/评卷计划
     * @param {*} userId 用户id
     * @param {*} planType 1、 考试 2 、评卷
     * @returns
     * @memberof testWorkerApi
     */
    static async checkHasSaveCheckList (data) {
        let info = {
            url: pathUrl + '/checkHasSaveCheckList',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // getAllUserCheckLog
    /**
     *
     *
     * @static
     * @param {String} examPlanId
     * @param {Number} type 1 考试 2评卷
     * @returns
     * @memberof testWorkerApi
     */
    static async getAllUserCheckLog (data) {
        let info = {
            url: pathUrl + '/getAllUserCheckLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
}
// /professionalApply/workerFirstPage
export default testWorkerApi
