import axios from 'axios'
import Qs from 'qs'
import { message } from 'antd'
import AllUrl from './util'
import { Modal } from 'antd'

function ExamError() { 
    Modal.error({
        title: 'Network Error',
        content: 'The network connection is unstable.Please contact working personnel.',
        onOk: () => { 
            if(window.require){
                const { ipcRenderer } = window.require("electron")
            ipcRenderer && ipcRenderer.send("exitApp", "");
            }
            window.location.href="/"
            
        }
    });
}

const Axios = axios.create({
    // 总
    baseURL: AllUrl.BaseUrl,
    timeout: 60000000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
//POST传参序列化(添加请求拦截器)
Axios.interceptors.request.use(
    config => {
        config.headers.Token_ = sessionStorage.token || ''
        config.headers.userIp = sessionStorage.userIp || '';
        return config
    },
    error => {
        message.error('请求失败!')
        return Promise.reject(error)
    }
)

//返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
    function (response) {
        const res = response.data
        // console.log(res)
        return res
    },
    function (error) {
        ExamError();
        // message.error(error.message)
        return Promise.reject(error.response.data)
    }
)

const Ajax = async ({ url, type, data, msg = '获取信息失败!' }) => {
    let c = null
    if (type === 'post') {
        if (!url.includes('uploadFile') && !url.includes('postFile')) {
            // for (const i in data) {
            //     data[i] = JSON.stringify(data[i]);
            // }
            data = Qs.stringify(data)
        }
        try {
            c = await Axios.post(url, data)
        } catch (error) {
            message.error(msg)
            c = false
            console.log(error)
        }
    }
    if (type === 'get') {
        let str = '?'
        for (const key in data) {
            str += (key + "=" + data[key] + "&")
        }
        if(str[str.length-1] == "?" || str[str.length-1] == "&" ){
            str=str.slice(0,str.length-1)
        }
        try {
            c = await Axios.get(url+str)
        } catch (error) {
            message.error(msg)
            c = false
            console.log(error)
        }
    }
    // debugger
    if (c.code === '-104' || c.code === "-105") {
        if (window.require) {
            const { ipcRenderer } = window.require("electron")
            setTimeout(() => {
                ipcRenderer.send("exitApp", "")
            }, 3000)
        } else {

            window.location.href = "/#/login"
        }
        message.error(c.message);
        return false;
    }
    if (c.code === '-101' ) {
        if (window.require) {
            const { ipcRenderer } = window.require("electron")
            setTimeout(() => {
                localStorage.clear()
                sessionStorage.clear()
                ipcRenderer.send("exit");
            }, 3000)
        } else {
            window.location.href = "/#/login"
        }
        message.error(c.message);
        return false;
    }
    return c
}

export default Ajax
