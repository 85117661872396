import Ajax from '../js/axios'
import getDate from '../js/formatDate'

const pathUrl = '/bus-pro-service'
// const pathUrl = '/TestWorker'
const pathUrl1 = '/studyapp-service'
const pathUrl2 = '/enterprise-service'
const pathUrl3 = '/auth-config-service'
const uuid = sessionStorage.getItem('uuid') || '';
class examinationApi {
    // 获取课程
    static async getOldCouseCatlogById (data) {
        let info = {
            url: pathUrl + '/course/getOldCouseCatlogById',
            type: 'get',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 获取课程
    static async testRequire (data) {
        let info = {
            url: pathUrl1 + '/trainClassTest/testRequire',
            type: 'get',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    static async getQuestionDetail(questionId,mainId){// 获取题详情
        let fo = {
            type:'get',
            url: pathUrl + '/question/getQuestionDetail?questionId=' + questionId + "&mainId=" + mainId,
        }
        let data = await Ajax(fo);
        return data;
    }
    static async getAnswerById(questionId){// 答案查询
        let fo = {
            type:'get',
            url: pathUrl + '/question/getAnswerById?questionId=' + questionId,
        }
        let data = await Ajax(fo);
        return data;
    }
    static async addAnswerRecord(data){  // 答案上传  单选
        let fo = {
            type:'post',
            url: pathUrl1 + '/AnswerRecord/addAnswerRecord',
            data
        }
        let up = await Ajax(fo);
        return {
            // answer,up
            up
        };
    } 

    // 单个题打分
    static async getScore (data) {
        let info = {
            url: pathUrl1 + '/score/getScore',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }

    // 新增录音记录
    static async addVideo (data) {
        let info = {
            url: pathUrl1 + '/TbStudyLog/addVideo',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // //测试结束
    static async endTest () {
        let data = {}
        let url = '/score/endTest';
        let trainClassId = sessionStorage.getItem('trainClassId') || ''
        if(trainClassId) {
            url = '/trainClassTest/endTest'
            data.trainClassId = trainClassId
        }
        
        data.rid = sessionStorage.getItem('rid') || '';
        data.uuid = sessionStorage.getItem('uuid') || ""
        data.starttime = sessionStorage.getItem('starttime')||''
        data.endtime =  getDate(new Date(),'yyyy-MM-dd hh:mm:ss')
        data.courseid = sessionStorage.getItem('curriculum')|| '' 
        let licenseType = sessionStorage.getItem('licenseType')|| '' 
        if(licenseType){
            data.licenseType = licenseType
        }
        let info = {
            url: pathUrl1 + url,
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 查询配置
    static async queryConfig() {
        let info = {
            url: pathUrl2 + '/tbEnterpriseConfig/queryConfig',
            type: 'get',
            data:{}
        }
        let data_ = await Ajax(info)
        return data_
    }
    //添加学习记录
    static async addTbStudyLog(data){
        let info = {
            url: pathUrl1 + '/TbStudyLog/addTbStudyLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
        // courseId: AC96AEB02E384FCFB4767EBA2948ADD1
        // courseName: 管制员英语测试考试指南学习软件
        // sectionAllName: 
        // sectionId: F833A197CA3847DDA411041EB074053C
        // sectionName: 
        // seatMealCourseId: abc
        // uuid: 15468062794057173301397501854896
    }
    // 学习笔记查询
    static async getNoteByQuestionId(id){// 答案查询
        let fo = {
            type:'get',
            url: pathUrl1 + '/CourseNote/getNoteByQuestionId?id=' + id + '&seatMealCourseId=abc',
        }
        let data = await Ajax(fo);
        return data;
    }
    //录音查询
    static async getNewVideo(id){
        let fo = {
            type:'get',
            url: pathUrl1 + '/TbStudyLog/getNewVideo?id=' + id ,
        }
        let data = await Ajax(fo);
        return data;
    }
    //添加笔记
    static async addCourseAdvice(data){
        let info = {
            url: pathUrl1 + '/CourseNote/addCourseAdvice',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
        // courseId: AC96AEB02E384FCFB4767EBA2948ADD1
        // nodeId: 3EEC85D7D6264C8EA887FAC24F46AC40
        // title: 
        // content: 1541541
        // questionId: D0A2F9622CDF43E0A5B9A5D76AF2B3F1
        // levelName:      Exchange 2
        // seatMealCourseId: abc
        // trainClassId: 
    }
    //查询防挂机时间
    static async getStopTimeConfig(data){
        let info = {
            url: pathUrl3 + '/dict/getStopTimeConfig',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 获取表格分数
    static async getTotalScore(){
        let fo = {
            type:'get',
            url: pathUrl1 + '/score/getTotalScore?uuid=' +  sessionStorage.getItem('uuid') || "",
        }
        let data = await Ajax(fo);
        return data;
    }

    static async getAvgScore(){
        let fo = {
            type:'get',
            url: pathUrl1 + '/score/getAvgScore?uuid=' +  sessionStorage.getItem('uuid') || "",
        }
        let data = await Ajax(fo);
        return data;
    }
    static async getIcaoScore(){
        let fo = {
            type:'get',
            url: pathUrl1 + '/score/getIcaoScore?uuid=' +  sessionStorage.getItem('uuid') || "",
        }
        let data = await Ajax(fo);
        return data;
    }
    // 心跳
    static async heartBeat(sectionId){
        let courseId = sessionStorage.getItem("curriculum")
        let enterpriseUserId = sessionStorage.getItem("enterpriseUserId")
        let setmealCourseId = sessionStorage.getItem("setmealCourseId")
        let setmealId = sessionStorage.getItem("setmealId")
        let fo = {
            type:'get',
            url: pathUrl1 + "/courseApp/heartBeat?courseId=" + courseId + '&enterpriseUserId=' + enterpriseUserId+ '&type=3' + '&sectionId=' + sectionId +  '&setmealCourseId=' + setmealCourseId + '&setmealId=' + setmealId
        }
        let data = await Ajax(fo);
        return data;
        // courseId: ED2F9C779DCD406C91AC76D4705BF3B1
        // type: 3
        // sectionId: 580CF469C4574E4D9AF892FFC172EDCB
        // setmealCourseId: 41ec7a00357f4bbe9c207998b55a42a8                                            
        // enterpriseUserId: 500225199502275213
        // setmealId: null
    }
    static async getCourseInfo(id){//获取课程信息
        let fo = {
            type:'get',
            url: pathUrl + "/course/getCourseInfo?courseId=" + id
        }
        let data = await Ajax(fo);
        return data;
    }






    // 登录首页计划列表
    static async getTodayGradingPlan (data) {
        let info = {
            url: pathUrl + '/getTodayGradingPlan',
            type: 'get',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 检查某个任务是否开启
    static async checkIsStart (data) {
        let info = {
            url: pathUrl + '/checkIsStart',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 添加开启任务记录
    static async addPlanOpenRecords (data) {
        let info = {
            url: pathUrl + '/addPlanOpenRecords',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 考试日志填写接口
    static async addCheckLog (data) {
        let info = {
            url: pathUrl + '/addCheckLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // 检查单填写
    static async addCheckList (data) {
        let info = {
            url: pathUrl + '/addCheckList',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // getAllOpenUser  
    static async getAllOpenUser (data) {
        let info = {
            url: pathUrl + '/getAllOpenUser',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkIsOpen 
    static async checkIsOpen (data) {
        let info = {
            url: pathUrl + '/checkIsOpen',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    static async getUserAnswer (data) {
        let info = {
            url: pathUrl1 + '/getUserAnswer',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkUserAnswerStatu
    /**
     *
     *
     * @static
     * @param {*} examPlanId   考试计划Id
     * @param {*}userIds    userId集合
     * @returns
     * @memberof testWorkerApi
     */
    static async checkUserAnswerStatu (data) {
        let info = {
            url: pathUrl1 + '/checkUserAnswerStatu',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    //  
    /**
     *
     *
     * @static
     * @param {String} id
     * @returns
     * @memberof testWorkerApi
     */
    static async getCheckListInfo (data) {
        let info = {
            url: pathUrl + '/getCheckListInfo',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkIsHaveSaveLog
    /**
     *
     *
     * @static
     * @param {string} userId 用户id
     * @param {string} logType 日志类型 1、监考 2 固定  3网络 4 现场
     * @param {Number} planType 计划类别  1 考试 2  评卷
     * @param {string}  planId 考试计划/评卷计划ID
     * @returns
     * @memberof testWorkerApi
     */
    static async checkIsHaveSaveLog (data) {
        let info = {
            url: pathUrl + '/checkIsHaveSaveLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // tbCheckLogService
    /**
     *
     *
     * @static
     * @param {*} data
     * @returns
     * @memberof testWorkerApi
     */
    static async tbCheckLogService (data) {
        let info = {
            url: pathUrl + '/tbCheckLogService',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // checkHasSaveCheckList
    /**
     *
     *
     * @static
     * @param {*} planId 考试/评卷计划
     * @param {*} userId 用户id
     * @param {*} planType 1、 考试 2 、评卷
     * @returns
     * @memberof testWorkerApi
     */
    static async checkHasSaveCheckList (data) {
        let info = {
            url: pathUrl + '/checkHasSaveCheckList',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }
    // getAllUserCheckLog
    /**
     *
     *
     * @static
     * @param {String} examPlanId
     * @param {Number} type 1 考试 2评卷
     * @returns
     * @memberof testWorkerApi
     */
    static async getAllUserCheckLog (data) {
        let info = {
            url: pathUrl + '/getAllUserCheckLog',
            type: 'post',
            data
        }
        let data_ = await Ajax(info)
        return data_
    }

    
}
export default examinationApi
