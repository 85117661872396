const success = require('../img/T_success.png');
const error = require('../img/T_error.png');
class Message {
    static main(type,msg){
        let tips = document.createElement('div');
        tips.className = `T_tips ${type}`;
        tips.innerHTML = `<img src=${type==='success'?success:error} /> <span>${msg}</span>`;
        let main = document.querySelector('#root');
        main.appendChild(tips);
        setTimeout(()=>{
            tips.className = `T_tips ${type} remove`;
        },1000)
        setTimeout(()=>{
            main.removeChild(tips);
        },1500)
    }
    static success(msg){
        this.main('success',msg);
    }
    static error(msg){
        this.main('error',msg);
    }
}

export default Message