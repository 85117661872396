import React, {Component} from 'react'
import './index.scss'
import Body from '../components/body'
import { inject, observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import {addTbStudyLog} from '@/js/commonFn'
import examinationApi from '@/AjaxApi/examinationApi';
@inject('Store','Examination','ExaminationStore')
@observer
class componentName extends Component {
    constructor(props){
        super(props)
        this.state={
            setTime:0,
            Prompt:false,
            outTime:'',//挂机时间(s)
            time: 0,
            intervalId:null,
            onHookInterval:null,
            isExamType: !(window.getQueryString('ExamType') == 'undefined'), //是否是考试
        }
    }
    componentDidMount(){
        window.addEventListener('mousemove',this.mousemove) 
        window.addEventListener('click',this.click)
        
        this.startStudy()
        this.setOutTime()

    }

    setOutTime = async () => {
        let res = await examinationApi.getStopTimeConfig()
        console.log('setOutTime',this.state.isExamType,window.getQueryString('ExamType'));
        this.setState({outTime: res.data * 60},()=>{
            if(window.getQueryString('ExamType') == 'undefined'){
                this.setHookUp()
            }
        })
    }

    componentWillUnmount(){
        window.removeEventListener('mousemove',this.mousemove)
        window.removeEventListener('click',this.click)

    }
    mousemove = ()=>{
        this.setState({setTime:0})
    }
    click = ()=>{
        let time = new Date();
        time = time.getTime() / 1000;
           console.log(time);
        sessionStorage.setItem('stopStydyTime',time); // 停止学习时间
        this.setState({setTime:0})
    }
    //计时器
    startStudy(){
        this.state.intervalId = setInterval(() => {
            this.setState({time:this.state.time++})
            if(this.state.Prompt){
                clearInterval(this.state.intervalId)
            }
        }, 1000)
    }
    setHookUp(){
        let ExamType = window.getQueryString('ExamType')
        if(ExamType == 'undefined'){
            console.log('setHookUp');
            this.state.onHookInterval = setInterval(()=>{
                console.log('setHookUp');
                let time = new Date();
                time = time.getTime() / 1000; 
                let oldTime = sessionStorage.getItem('stopStydyTime');
                oldTime = parseInt(oldTime);
                    let rTime = (time - oldTime) -  this.state.outTime;
                    console.log(rTime);
                    if(rTime>0){ // 超出8 分钟
                        console.log((oldTime +  this.state.outTime) * 1000,123123);
                        sessionStorage.setItem("chapterSuspendTime",(oldTime +  this.state.outTime) * 1000)
                        clearInterval(this.state.onHookInterval)
                        this.setState({setTime:0,Prompt:true})
                        return false;
                    }
            },1000)
        }
    }
     //继续学习
     continueStudy(){
        this.setState({Prompt:false})
        sessionStorage.setItem("chapterSuspendTimeEnd",Date.parse(new Date()))
        this.setHookUp()
        this.startStudy()
        // this.$refs.video && this.$refs.video.play()
    }
    handleOkGJ = async ()=>{
        console.log(this.props);
        await addTbStudyLog(this.props)


        let ipcRenderer = window.require("electron").ipcRenderer
        if(ipcRenderer){
            localStorage.clear()
            sessionStorage.clear()
            ipcRenderer.send("exit");
        }
    }
    handleCancelGJ = ()=>{
        this.continueStudy()
    }
    okText = () => (
        <p>Continue</p>
    )
    cancelText = () => (
        <p>Quit</p>
    )
    render() {
        let {isExamType} = this.state
        return (
            <div className='Main_box'>
                <Modal title="Hint"  centered visible={this.state.Prompt} onOk={this.handleCancelGJ } onCancel={this.handleOkGJ} zIndex={99999} okText={this.okText()} cancelText={this.cancelText()}>
                    <p>You have note studied for a long time. The study has been suspended.</p>
                 
                </Modal>
                <div className='Main_head'>
                    {
                        isExamType ? 
                        <h1>AIR TRAFFIC CONTROLLER ENGLISH TEST SYSTEM</h1>
                        :
                        <h1>AIR TRAFFIC CONTROLLER ENGLISH SIM-TEST SYSTEM</h1>
                    }
                    
                </div>
                <Body />
            </div>
        )
    }
}

export default componentName
