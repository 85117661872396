/*
 * @Author: LG
 * @Date: 2019-12-13 09:24:57
 * @Description: 
 * @Last Modified By: LG
 * @Last Modified Time: 2020-09-22 18:50:45
 */
import { observable, action, ObservableMap } from 'mobx'

class Store {
    @observable one = '';
    @observable check = false;
    @observable clientNumber = 1;
    @observable quit = 1;
    @action setItem = ({ key, value }) => {
        // console.log(value)
        this[key] = value;
    }

}

export default new Store();