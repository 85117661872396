/*
 * @Author: LG
 * @Date: 2019-12-13 09:25:00
 * @Description: 
 * @Last Modified By: LG
 * @Last Modified Time: 2020-09-14 13:29:23
 */
import Loadable from 'react-loadable'
import DelayLoading from '../components/DelayLoading'

import Examination from './Examination'
import Reviewer from './Reviewer'
import Monitor from './Monitor'

// const home = Loadable({ loader: () => import('../views/Home/index'), loading: DelayLoading, delay: 3000 })
const Login = Loadable({ loader: () => import('../views/Login/index'), loading: DelayLoading, delay: 3000 })
const ErrorPage = Loadable({ loader: () => import('../views/ErrorPage/networkError'), loading: DelayLoading, delay: 3000 })
const BreakExam = Loadable({ loader: () => import('../views/Login/breakExam'), loading: DelayLoading, delay: 3000 })

export default [
    // {
    //     path: '/',
    //     component: Login
    // },
    {
        path: '/Login',
        component: Login
    },
    {
        path: '/ErrorPage',
        component: ErrorPage
    },
    {
        path: '/BreakExam',
        component: BreakExam
    },
    ...Monitor,
    ...Examination,
    ...Reviewer
]
