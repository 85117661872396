/*
 * @Author: LG
 * @Date: 2020-05-20 14:45:28
 * @Description: 
 * @Last Modified By: LG
 * @Last Modified Time: 2020-09-14 14:08:33
 */
import { observable,action } from 'mobx'
import { create, persist } from 'mobx-persist'

class Reviewer {
    @persist @observable userId = '';
    @persist @observable Type = '评卷';   // 培训 评卷
    @persist('list') @observable TypeList = []; // 当前题型问题

    @persist @observable mode = '正式评卷'; // 正式评卷  模拟评卷   培训   考核
    @persist @observable simulateGradingPattern = 3;// 模拟评卷 状态  1 无模拟评卷  2 模拟评卷模式一   3  模拟评卷模式二
    @persist @observable CurrentTitle = '';
    
    @persist('object') @observable AllData = {}; // 获取 问题所有数据 （包括 专家评分）
    @persist('object')  @observable GradingInfo = {}; // 评卷计划信息

    @persist('list') @observable passRule = [];// 模拟评卷 纬度 通过 数
    @persist('list')  @observable simulationRule = [];
    // 模拟评卷 问题通过数
    @persist('object') @observable simulateData = {};// 模拟评卷  所有数据

    @persist('list') @observable QuestionList = []; // 当前题型问题
    @persist @observable QuestionIndex = 0;
    @persist('list') @observable stemList = [];  // 题干 文本 列表
    @persist @observable stemIndex = 0;// 当前题干文本 下标
    @persist('object') @observable GradedNum = {};  // 评卷员(复评员)本次评卷的各试题数量
    @persist('object') @observable GradedUnNum = {};  // 评卷员(复评员)本次评卷的各试题剩余数量

    @persist('object') @observable MaxTime = {};  // 评卷员(复评员)本次评卷的各试题数量
    @persist('object') @observable MinTime = {} //评卷员(复评员) 最小评分时间

    
    @persist @observable changeShowRemember = false; //是否记住
    @observable changeShow = false; // 题型切换  提示
    @observable resultShow = false; // 模拟评卷 结果查看
    @observable tipsShow = false; // 提示框
    @observable tipsInfo = {}; // 提示框文本

    @observable RuleInfo = [];//  评分规则

    @observable ResultData = {}; // 练习结果 数据
    @persist('list') @observable evaluateLabel = [];// 试题标签
    @persist('list') @observable examinerLabel = [];// 考官标签
    
    @action setItem = ({key,value})=>{
        this[key] = value;
    }
    @action QuestionSet = (data)=>{
        for (const i in data) {
            this[i] = data[i];
        }
    }

    @action BaseSet = (data)=>{
        for (const i in data) {
            this[i] = data[i];
        }
    }
}


const hydrate = create({})
export const ReviewerStore = new Reviewer();
hydrate('ReviewerStore', ReviewerStore).then(() => console.log('ReviewerStore has been hydrated'));
