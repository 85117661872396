/*
 * @description  : 
 * @Author       : Junfeng Fu
 * @Date         : 2021-03-26 15:07:56
 * @LastEditors  : Junfeng Fu
 * @LastEditTime : 2021-11-01 14:09:43
 */
import { observable,action,computed } from 'mobx'
import { create, persist } from 'mobx-persist'


class Examination {

    @persist('object') @observable StompObject = null;  //中断服务连接对象
    @persist @observable BreakServeState = false;  //中断服务连接状态

    @persist @observable ExamType = 'Formal'; // Formal 正式考试 Mock 模拟考试
    @persist('list') @observable MenuList = []; // 菜单 list
    @persist('list') @observable choice = [];
    @persist('list') @observable story = [];
    @persist('list') @observable answerQuestion = [];
    @persist('list') @observable analog = [];
    @persist('list') @observable opi = [];
    @persist('object') @observable nextQuestion = {};
    @persist @observable questionType = '';
    @persist @observable currentIndex = 0;
    @persist @observable opiWay = ''; 
    @persist @observable type = ''; // type 1 初试 2 复试  
    @persist @observable opiOrder = '';
    @persist @observable userId = '';
    @persist('object') @observable allTime = {};
    @persist('list') @observable defaultList = [];
    @persist @observable FirstQuestion = true; // 判断 是否为第一题
    @persist @observable ChangeType = 0; // 0 题型 改变  1 题型下 一大题改变 
    @persist @observable oldSmallIndex = 0; // 做题记录 最后一题 index
    @persist('list') @observable oldSmallList = []; // 做题记录 原 list
    @persist @observable OpiMinTime = 480;// opi 面试最低时间
    @observable canTest = true;// 能否考试
    @observable audioVolume = 100; // 总音量 设置

    @persist('object') @observable examineeArrange = {}; // 考试信息

    @observable TopicSwitching = false; // 选择题 TopicSwitching 题型切换提示音 （6 7 8 大题  6 9 12 小题 ）
    @persist @observable ChoiceSmallIndex = 0; // 选择题 小题下标
    @persist @observable ChoiceScore = 0; // 选择题 分数
    @action BaseSet = (data)=>{
        for (const i in data) {
            this[i] = data[i];
        }
    }

    @observable volume = 0;// 音量大小

    @action TypeChange = async(data)=>{
        for (const i in data) {
            this[i] = data[i];
        }
        return true;
    }

    @action setItem = ({key,value})=>{
        this[key] = value;
    }

    @computed get CurrentItem(){
        let item = {
            title: '',
            type: '',
            shu: 0,
            path: '',
            index:0,
        };
        this.defaultList.forEach((el,index)=>{
            if(el.type === this.questionType){
                item = JSON.parse(JSON.stringify(el));
                item.index = index;
            }
            return el;
        })
        return item;
    }
}

const hydrate = create({  })
export const ExaminationStore = new Examination();
hydrate('ExaminationStore', ExaminationStore).then(() => console.log('ExaminationStore has been hydrated'));

