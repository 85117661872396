import { observable, action } from 'mobx'
import { StoreModule, Store } from '../js/mobx-store'
import { create, persist } from 'mobx-persist'
import testWorkerApi from '../AjaxApi/testWorkerApi'
import { message } from 'antd'
// const { userId } = sessionStorage.userInfo?JSON.parse(sessionStorage.userInfo).id:""
class MonitorStore extends StoreModule {
    @persist("list") @observable Tasklist = []
    @persist @observable examClassIds = ""
    @persist @observable examClassNames = ""
    @persist @observable examCode = ''
    @persist @observable examDescribe = ''
    @persist @observable examinationId = ''
    @persist @observable openUserType = ''
    @persist @observable testOpenUserType = ''
    @persist @observable addType = ''
    @persist @observable planType = ''  //1 考试  2评卷
    @persist @observable examPlanId = ''
    @persist @observable roomIds = ""
    @persist @observable examPlanType = ""
    @persist("object") @observable nvrInfo = {}
    @persist @observable workState = 0
    @persist("object") @observable checkList = {}
    @persist @observable userId = ""
    @persist @observable mobile = ""
    @persist @observable examinatinId = ""
    @persist("list") @observable examClassExaminatinIds = []
    @persist @observable examtinClassName = ""
    @persist @observable roomNames = ""
    @persist @observable logIsSave = 0
    @persist("object") @observable logItem = {}
    @persist @observable checkListIsSave = 0
    @persist @observable examinatinIdByExamPlan = ""
    @persist("object") @observable checkList = {}
    @persist("list") @observable allUser = []
    @persist @observable examDescribe = ""
    @action setExamDescribe = data => {
        this.setState({ examDescribe: data })
    }
    @action setExaminatinIdByExamPlan = data => {
        this.setState({ examinatinIdByExamPlan: data })
    }
    @action setAllUser = data => {
        this.setState({
            allUser: data
        })
    }
    @action setExamCode = data => {
        this.setState({
            examCode: data
        })
    }
    @action setTaskList = data => {
        // sessionStorage.setItem("Tasklist", JSON.stringify(data))
        this.setState({
            Tasklist: data
        })
    }
    @action setExaminationId = data => {
        this.setState({
            examinationId: data
        })
    }
    @action setUserId = data => {
        this.setState({
            userId: data
        })
    }
    @action setOpenUserType = data => {
        // sessionStorage.setItem('openUserType', data)
        console.log(data)
        this.setState({
            openUserType: data
        })
    }
    @action setTestOpenUserType = data => {
        // sessionStorage.setItem('testOpenUserType', data)
        this.setState({
            testOpenUserType: data
        })
    }
    @action setAddType = data => {
        // sessionStorage.setItem('addType', data)
        this.setState({
            addType: data
        })
    }
    @action setExamClassIds = data => {
        // sessionStorage.setItem("examClassIds", data)
        this.setState({
            examClassIds: data
        })
    }
    @action setExamClassName = data => {
        // sessionStorage.setItem("examClassName", data)
        this.setState({
            examClassNames: data
        })
    }
    @action setPlanType = data => {
        // sessionStorage.setItem('planType', data)
        this.setState({
            planType: data
        })
    }
    @action setExamPlanId = data => {
        // sessionStorage.setItem("examPlanId", data)
        this.setState({ examPlanId: data })
    }
    @action setExamDescribe = data => {
        this.setState({
            examDescribe: data
        })
    }
    @action setRoomIds = data => {
        this.setState({
            roomIds: data
        })
    }
    @action setExamtinClassNames = data => {
        // sessionStorage.setItem("examtinClassNames", data)
        this.setState({
            examtinClassNames: data
        })
    }
    @action setExamPlanType = data => {
        this.setState({
            examPlanType: data
        })
    }
    @action setNvr = data => {
        this.setState({
            nvrInfo: data
        })
    }
    @action setWorkState = data => {
        // sessionStorage.setItem("workState", data)
        this.setState({
            workState: data
        })
    }
    @action setCheckList = data => {
        // sessionStorage.setItem("checkList", JSON.stringify(data))
        this.setState({
            checkList: data
        })
    }
    @action setMobile = data => {
        // sessionStorage.setItem("mobile", data)
        this.setState({
            mobile: data
        })
    }
    @action setExamClassExaminatinIds = data => {
        // console.log(data)
        // debugger
        // sessionStorage.setItem("examClassExaminatinIds", data)
        this.setState({
            examClassExaminatinIds: data
        })
    }
    @action setExaminatinId = data => {
        // sessionStorage.setItem("examinatinId", data)
        this.setState({ examinatinId: data })
    }
    @action setRoomNames = data => {
        // sessionStorage.setItem("roomNames", data)
        this.setState({ roomNames: data })
    }
    @action setLogIsSave = data => {
        // sessionStorage.setItem("logIsSave", data)
        this.setState({ logIsSave: data })
    }
    @action setLogItem = data => {
        this.setState({ logItem: data })
    }
    @action getLog = async () => {
        const {
            addType,
            examPlanId,
            planType,
            userId
        } = this;
        console.log(userId, "zzz")
        testWorkerApi.checkIsHaveSaveLog({
            logType: addType,
            userId,
            planId: examPlanId,
            planType
        }).then(res => {
            if (+res.code === 200) {
                this.setState({ logIsSave: res.data.type, logItem: res.data.userCheckLog })
            } else {
                message.error(res.message)
            }
        })
    }
    @action getCheckList = async () => {
        const { planType, examPlanId, userId, addType } = this
        console.log(this)
        testWorkerApi.checkHasSaveCheckList({
            userId,
            planType, addType,
            planId: examPlanId
        }).then(res => {
            if (+res.code === 200) {
                this.setState({
                    checkListIsSave: res.data.has,
                    checkList: res.data.checkList
                })
            }
        })
    }
}
const hydrate = create({})
export const Monitor = new Store({ MonitorStore }).MonitorStore
hydrate('Monitor', Monitor).then(() => console.log('Monitor has been hydrated'));