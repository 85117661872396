import Loadable from 'react-loadable'
import DelayLoading from '../components/DelayLoading'

const Home = Loadable({loader: () => import('../views/Reviewer/home/index'), loading : DelayLoading,delay:3000});
const Judging = Loadable({loader: () => import('../views/Reviewer/Judging/index'), loading : DelayLoading,delay:3000});



export default [
    // {
    //     path:'/',
    //     component:Home,
    // },
    {
        path:'/Reviewer/home',
        component:Home,
    },
    {
        path:'/Reviewer/officialReview',
        component:Judging,
    },
]