import React, {Component} from 'react'
import { message } from 'antd';
import { observer,inject } from 'mobx-react';

@inject('Store','OpiStore')
@observer
class OverTips extends Component {
    constructor(props){
        super(props)
        this.CountTime = null;
        this.state={
            count:10,
        }
    }
    componentDidMount(){
        this.CountTime = setInterval(()=>{
            let {count} = this.state;
            if(count>0){
                count--;
                this.setState({count});
            }else{
                clearInterval(this.CountTime);
                this.CountTime = null;
                this.Exit();
            }
        },1000)
    }
    componentWillUnmount(){
        if(!!this.CountTime){
            clearInterval(this.CountTime);
        }
    }
    Exit = async()=>{
        message.success('面试完成!');
        // let { quit } = this.props.Store;
        // this.props.Store.setItem({ key: 'quit', value: quit + 1 });
        this.props.Close();
    }
    render() {
        let {UpdateType} = this.props.OpiStore;
        let {count} = this.state;
        return (
            <div className='Over_tips'>
                {
                    UpdateType==='success'?<img src={require('@/img/update_success.png')} className='t' alt=""/>:<img src={require('@/img/update_success.png')} className='t' alt=""/>
                }
                <h1>
                    {UpdateType==='success'?'本次考试数据上传成功':'本次考试数据上传存在问题'}
                </h1>
                <h2>
                    {UpdateType==='success'?'考试已结束，谢谢！':'请与监考人员联系，谢谢！'}
                </h2>
                <button onClick={ this.Exit }>退出</button>
                <h3>
                    {count}s后系统将自动退出
                </h3>
                <img src={require('@/img/Fly.png')} className='fly' alt=""/>
            </div>
        )
    }
}

export default OverTips
