import Loadable from 'react-loadable'
import DelayLoading from '../components/DelayLoading'

const Home = Loadable({
    loader: () => import('../views/Monitor/Home/backUp'),
    loading: DelayLoading,
    delay: 5000
})
const CheckList = Loadable({
    loader: () => import('../views/Monitor/Home/CheckList'),
    loading: DelayLoading,
    delay: 3000
})
const Workbench = Loadable({
    loader: () => import('../views/Monitor/Home/workbench'),
    loading: DelayLoading,
    delay: 3000
})
const Log = Loadable({
    loader: () => import('../views/Monitor/Home/Log'),
    loading: DelayLoading,
    delay: 3000
})
export default [
    {
        path: '/Monitor/Home',
        component: Home
    },
    {
        path: '/Monitor/CheckList',
        component: CheckList
    },
    {
        path: '/Monitor/Workbench',
        component: Workbench
    },
    {
        path: '/Monitor/Log',
        component: Log
    }
]
