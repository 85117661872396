/*
 * @Author: LG
 * @Date: 2019-12-13 09:24:58
 * @Description: 
 * @Last Modified By: LG
 * @Last Modified Time: 2020-09-18 10:47:21
 */
import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Layouts from './Layouts'
import { Provider } from 'mobx-react'
import store from '../store/index'
import {ReviewerStore} from '../store/Reviewer'
import {ExaminationStore} from '../store/Examination' 
import {ExaminationNewStore} from '../store/ExaminationStore'
import {OpiStore} from '../store/OpiStore'
import Stores from '../store/main'
import {configure} from 'mobx'

console.log(Stores)
configure({
    enforceActions:'never',
})
class Routers extends Component {
    constructor(props) {
        super(props)
        this.pathname = this.props.location.pathname
        this.state = {
            complete: false,
        }
    }
    UNSAFE_componentWillMount(){
        
    }

    async componentDidMount() {
        window.ReactHistory = this.props.history;
        this.setState({ complete: true });
    }


    render() {
        let { complete } = this.state;
        return (
            <Provider
                Store={store}
                Reviewer={ReviewerStore}
                Examination={ExaminationStore}
                ExaminationStore={ExaminationNewStore}
                OpiStore={OpiStore}
                {...Stores}
            >
                <Switch>
                    {complete ? (
                        <>
                            <Route path="/" component={Layouts} />
                            {/* <Redirect to="/" />
                            <Layouts /> */}
                        </>
                    ) : null}
                </Switch>
            </Provider>
        )
    }
}
export default withRouter(Routers)
