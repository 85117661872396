const zero = time => {
  if (time < 10) {
    return '0' + time
  } else {
    return time
  }
}
const getDate = (dates, type) => {
  const date = new Date(dates)
  if (!date.getDate()) {
    console.log(new Error('传入的不是一个正确的日期对象'))
    return ''
  } else {
    if (type === 'yyyy') {
      return date.getFullYear()
    } else if (type === 'yyyy-MM') {
      return date.getFullYear() + '-' + zero(date.getMonth() + 1)
    } else if (type === 'yyyy-MM-dd') {
      return (
        date.getFullYear() +
        '-' +
        zero(date.getMonth() + 1) +
        '-' +
        zero(date.getDate())
      )
    } else if (type === 'yyyy-MM-dd hh') {
      return (
        date.getFullYear() +
        '-' +
        zero(date.getMonth() + 1) +
        '-' +
        zero(date.getDate()) +
        ' ' +
        zero(date.getHours())
      )
    } else if (type === 'yyyy-MM-dd hh:mm') {
      return (
        date.getFullYear() +
        '-' +
        zero(date.getMonth() + 1) +
        '-' +
        zero(date.getDate()) +
        ' ' +
        zero(date.getHours()) +
        ':' +
        zero(date.getMinutes())
      )
    } else if (type === 'yyyy-MM-dd hh:mm:ss') {
      return (
        date.getFullYear() +
        '-' +
        zero(date.getMonth() + 1) +
        '-' +
        zero(date.getDate()) +
        ' ' +
        zero(date.getHours()) +
        ':' +
        zero(date.getMinutes()) +
        ':' +
        zero(date.getSeconds())
      )
    } else if (type === 'hh') {
      return zero(date.getHours())
    } else if (type === 'hh:mm') {
      return zero(date.getHours()) + ':' + zero(date.getMinutes())
    } else if (type === 'hh:mm:ss') {
      return (
        zero(date.getHours()) +
        ':' +
        zero(date.getMinutes()) +
        ':' +
        zero(date.getSeconds())
      )
    }
  }
}

export default getDate
