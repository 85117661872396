import { observable,action,computed } from 'mobx'
import { create, persist } from 'mobx-persist'

class Examination {
    @persist('list') @observable menu = []; //课程
    @persist('list') @observable indexArray = [0]; //索引
    @persist('list') @observable MenuList = []; //课程菜单
    @persist('list') @observable questionList = []; //题列表
    @persist('object') @observable extendData = {}; //扩展数据 
     @observable hasAudioInput = true; //是否有麦克风
     @observable hasAudioOutput = true; //是否有耳机
     @observable ExaminationNo = ""; //考试编号
    
    @observable testIndex = 0; //当前test 索引
     @observable currentChapterIndex = 0; //当前章节 索引
     @observable currentTopicIndex = 0; //当前题 索引
     @observable headUrl = ""; //用户头像

     @observable interviewTimeCount = 0; // opi考试时长
     @observable showInterviewTimeCount = false; // 是否展示 opi考试时长

    @persist('list') @observable pathMap = [ 
        {
            questionTempId:"6E019A673B4147F68F7A599C46F304B1",      //单问选择（单）4
            path:"/Examination/choice"
        },
        {
            questionTempId:"04F485861F9D4BCC9FDED30501EC0314",      //多问选择（单）4
            path:"/Examination/choice"
        },
        {
            questionTempId:"E5E6AA5B78E746A59FDC89A24217B148",      //故事复述  4
            path:"/Examination/storyRetelling"
        },
        {
            questionTempId:"EFDCCE2E0ADD403B897DCA97679CC5F5",      //简要回答  3
            path:"/Examination/ShortAnswer"
        },
        {
            questionTempId:"CC49F712751D452799FD9A9DD564F698",      //模拟练习  3
            path:"/Examination/analog"
        },
        {
            questionTempId:"93169DC3FD6D4BADA4DE0D02AD0567E6",      //人机面试OPI   4
            path:"/Examination/opi"
        },
    ]; //每种题型对应路径映射

    @computed get getCurrentChapter(){ // 当前章节
        return this.menu[0].childList[this.currentChapterIndex]
    }

    @computed get getCurrentTopic(){ // 当前题
        if(this.getCurrentChapter.sectionMain ){
            return this.getCurrentChapter.questionList[this.currentTopicIndex] 
        }else{
            return this.getCurrentChapter.childList[0].questionList[0].childQuestionList[0]
        }
    }
    
    @computed get getBindRole(){ // 当前绑题规则
        return this.getCurrentChapter.bindRole
    }

    @computed get interviewTime(){
        let time = window.getTime(this.interviewTimeCount);
        return time;
    }


    @computed get getQuestionListItem(){ // 当前绑题规则
        let item = this.getQuestionList[this.currentTopicIndex]
        return item
    }

    @computed get getQuestionList(){ // 当前绑题规则
        // 判断是否是最后一单元
        if(this.testIndex == this.menu.length ) console.log("学习已完成");

        let item = this.menu[this.testIndex]
        if(this.menu[this.testIndex].questionList){
            item = this.menu[this.testIndex].questionList      
        }else{
            for (let i = 0; i < this.indexArray.length ; i++) {
                item = JSON.parse( JSON.stringify( this.findChildList( item , this.indexArray[i] )  ) )         
            }
        }
        console.log('return item',item);
        return item
    }
    findChildList (menu,index){
        if(menu.questionList){
            let questionList = menu.questionList
            return questionList 
        }else{
            let childList = menu.childList[index]
            return  childList
        }
    }
    


    @action.bound  Jump= ({key,value})=>{
        
    }
    @action.bound setItem = ({key,value})=>{
        this[key] = value;
    }
}

// export default new ExaminationNewStore();
const hydrate = create({  })
export  const ExaminationNewStore = new Examination();
hydrate('ExaminationNewStore', ExaminationNewStore).then(() => console.log('ExaminationNewStore has been hydrated'));
