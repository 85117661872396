import React, { Component ,useState,useEffect} from 'react'
import Loading from '@/components/Loading/index'
import { Route, withRouter} from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import routerConfig from '@/routers/index'
import Message from '../../../js/tips';
import Over from './OverTips'
import {message} from 'antd'


import AjaxApi from '@/AjaxApi/examination'
import { Modal } from 'antd'

@inject('Store', "Monitor", 'OpiStore','Examination')
@observer
class Body extends Component {
    constructor(props) {
        super(props)
        this.Ws = null;
        this.StompClient=null;
        this.state = {
            complete: true,
            quit: props.Store.quit,
            clientNumber: props.Store.clientNumber,
            reTry:0
        }
    }

    componentDidMount () {
    }


    componentDidUpdate () {
    }
    render () {
        let { OverShow } = this.props.OpiStore;
        console.log(this.props.Store.quit, this.props.Store.clientNumber) //此处必须打印一下，否则componentDidUpdate不执行
        // let { quit, clientNumber } = this.props.Store;
        // let token = sessionStorage.token || '';
        return (
            <>
                {
                    this.state.complete ? <Loading>
                        {routerConfig.map((route, i) =>
                            <Route key={i} path={route.path} exact
                                render={(props) => {
                                    // if(!!token || route.path==='/Login'){
                                    return <route.component {...props} route={route} />
                                    // }else{
                                    // return <Redirect to={{ pathname: '/Login' }} />
                                    // }
                                }}
                            />
                        )}
                    </Loading> : null
                }
                {
                    OverShow ? <>
                        <div className='Cover_over'></div>
                        <Over Close={this.CloseWindow} />
                    </> : null
                }
            </>
        )
    }
}

export default withRouter(Body)
