/*
 * @Author: LG
 * @Date: 2020-03-17 16:30:57
 * @Description: 
 * @Last Modified By: LG
 * @Last Modified Time: 2020-08-18 20:50:05
 */
import { observable,action, computed } from 'mobx'
import { create, persist } from 'mobx-persist'


class Opi{
    // 面试状态  BeginBefore 匹配考生 Matching 匹配中  MatchingSuccess 匹配成功  MatchingFailed 匹配失败  NetworkError 网络连接失败  Interview 面试中   InterviewUpdate 面试完成上传数据  UpdateComplete 面试完成上传数据完成
    @observable Status = 'BeginBefore'; 
    @persist @observable UserType = '考生'; // 考官  考生
    @persist @observable StudentId = '';//  考官获取 匹配考生的 id
    @persist @observable TeacherId = '';// 考生 获取 考官 id
    @persist @observable TeacherName = ''; // 考生 获取 考官姓名
    @persist @observable OpiInterviewStartTime = '';// opi 面试 开始时间
    @observable RecordShow = false;
    @observable OverShow = false;
    @observable UpdateType = ''; // success error 
    @observable OverInterviewShow = false;
    @observable uuid = ''; //  角色 本身uuid
    @observable targetUuid = ''; // 接收到的 uuid
    @observable interviewTimeCount = 0; // 面试时长 
    @persist @observable interviewLimitTime = 480; // 默认 8分钟时间
    @persist @observable computedInterview = false; // 机器面试官
    @observable computedInterviewStatus = '';// 机器面试 进度   recording
    @observable computedInterviewTime = '';// 机器面试 进度   recording
    @persist @observable volume = 0; // 音量
    @observable testShow = true; // 设备检测
    @observable isSetUp = false; // 进入房间 是否 初始化完成
    @observable timeUpInfo = false; // 10分钟 上传 数据
    @persist('object') @observable enterpriseData = { //房间参数
        apiServer:'',
        mcuHost:'',
        alias:'',
        password:'',
        displayName:'',
        roomId:'',
    }
    @persist('object') @observable roomJsonOptions = { // 房间参数
        serial: '352024890',
        password: '8605',
    }
    @observable Room = new window.ZjRTC();

    @persist('list') @observable smallList = [];
    @persist @observable smallIndex = 0;
    @persist @observable QueueCount = 0; // 队列人数
    @observable currentQueue = 0;// 考生当前位置
    @persist @observable InterviewCount = 0;//已面试人数
    @persist('list') @observable InterviewRecord = [];// 面试记录
    @persist('object') @observable InterviewAllInfo = {};//面试记录 所有信息
    @observable ExamNumber = '';// 面试期数
    @observable targetStream = null; // 视频流
    @observable recordId = ''; // 本次录制的唯一ID	
    @observable nextQuestionTime = 48; // 下一道题 倒计时
    @persist @observable minAnswerTime = 48;
    @persist @observable maxAnswerTime = 60;



    @computed get canOver(){
        // console.log(this.interviewLimitTime,this.interviewTimeCount)
        if(this.interviewTimeCount>=this.interviewLimitTime){
            return true;
        }else{
            return false;
        }
    }
    @computed get interviewTime(){
        let time = window.getTime(this.interviewTimeCount);
        return time;
    }
    @action setItem = ({key,value})=>{
        this[key] = value;
    }
    @action Close = ()=>{
        this.OverInterviewShow = false;
        this.RecordShow = false;
        this.OverShow = false;
    }
    @action BaseSet = (data)=>{
        for (const i in data) {
            this[i] = data[i];
        }
    }
    @action ResetRoom = ()=>{
        this.Room = null;
        this.Room = new window.ZjRTC();
    }
    @action DisconnectAll = ()=>{
        this.Room.disconnectAll();
        this.Room = null;
        this.Room = new window.ZjRTC();
    }
}

const hydrate = create({})
export const OpiStore = new Opi();
hydrate('OpiStore', OpiStore).then(() => console.log('OpiStore has been hydrated'));