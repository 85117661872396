import AllUrl from './util'
{
    let c = document.documentElement.clientWidth;
    if (c > 1920) {
        c = 1920;
    }
    document.documentElement.style.fontSize = c / 19.2 + "px";
}
window.onresize  = function(){
    let c = document.documentElement.clientWidth;
    if (c > 1920) {
        c = 1920;
    }
    document.documentElement.style.fontSize = c / 19.2 + "px";
}

window.fileUrl = function(src){
    return AllUrl.fileUrl + src;
}

window.fileUrlTwo = function(src){
    return AllUrl.fileTwo + src;
}

window.switchTime = (time)=>{
    let m = parseInt(time/60);
    let s = parseInt(time%60);
    if(m<10) m = '0' + m;
    if(s<10) s = '0' + s;
    return m + ':' + s;
}

window.getQueryString=function(name){
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");   
    var r = window.location.search.substr(1).match(reg);   
    if (r != null) return decodeURI(r[2]); return null;  
}


window.ReplaceB = function(str){
    if(!!!str) return '';
    return str.replace(/\n/g,'<br/>')||'';
}


window.GetTimeNow = function(){
    let time = new Date();
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getUTCDate();
    if(m<10) m = '0' + m;
    if(d<10) d = '0' + d;
    return y + '-' + m + '-' + d;
}


window.TypeList = ['XZT','GSFS','JDT','MNTH','OPI'];

window.CheckType = function(v){
    v = parseInt(v);
    if(v === 1 || v === 2 || v === 3 || v === 4){
        return 'choice';
    }else if(v === 5){
        return 'story'
    }else if(v === 6){
        return 'answerQuestion'
    }else if(v === 7){
        return 'opi'
    }else if(v === 8 || v === 9){
        return 'analog'
    }else{
        return '';
    }
}



window.AllDataChange = function(data){
    let allData = JSON.parse(sessionStorage.getItem('AllData'));
    for (const i in data) {
        allData[i] = data[i];
    }
    sessionStorage.setItem('AllData',JSON.stringify(allData));
}

window.getTime = (time)=>{
    if(typeof(time)==='string' && time.indexOf(':')>-1){
        return time;
    }
    let s = time%60;
    let m = Math.floor(time/60%60);
    let h = Math.floor(time/3600);
    if(m<10) m = '0' + m;
    if(s<10) s = '0' + s;
    if(h<10) h = '0' + h;
    return h + ':' + m + ':' + s;
}

window.getTimeOne = (time)=>{
    if(typeof(time)==='string' && time.indexOf(':')>-1){
        return time;
    }
    let s = time%60;
    let m = Math.floor(time/60%60);
    let h = Math.floor(time/3600);
    if(m<10) m = '0' + m;
    if(s<10) s = '0' + s;
    if(h<10) h = '0' + h;
    return {h,m,s};
}

window.GetTimeHM = (time)=>{
    time = new Date(time)
    // let y = time.getFullYear()
    // let M =
    //     time.getMonth() + 1 < 10
    //         ? '0' + (time.getMonth() + 1)
    //         : time.getMonth() + 1
    // let d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
    let h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
    let m = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
    return h + ':' + m
}


window.QuestionType = (v)=>{
    v = parseInt(v);
    let type = '';
    let index = 0;
    let name = '';
    if(parseInt(v) === 5){
        type = 'story';
        index = 0;
        name = '故事复述';
    }
    if(parseInt(v) === 6){
        type = 'answerQuestion';
        index = 1;
        name = '简答题';
    }
    if(parseInt(v) === 7 || parseInt(v) === 8){
        type = 'opi';
        index = 2;
        name = parseInt(v)===7?'机器OPI':'人工OPI';
    }

    if(parseInt(v) === 9 || parseInt(v) === 10){
        type = 'simulation';
        index = 3;
        name = '模拟通话';
    }
    return {type,name,index};
}



window.GetStoreData = (data)=>{
    let data_ = {};
    for (const i in data) {
        let type = typeof(data[i]);
        if(type !== 'function'){
            data_[i] = data[i];
        }
    }
    return JSON.stringify(data_);
}
window.Debounce =(fn, ms = 500)=> {
    let timeoutId
    return function () {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            fn.apply(this, arguments)
        }, ms)
    }
}

window.TagReplace = (str)=>{
    let reg = /<[^>]*>|<\/[^>]*>/gm;
    if(str){
        str = str.replace(reg,'');
        str = str.replace(/&nbsp;/g,' ');
        str = str.replace(/&ldquo;/g,'"');
        str = str.replace(/&rdquo;/g,'"');

    }
    return str||'';
}