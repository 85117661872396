import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { inject,observer } from 'mobx-react'
import { Spin } from 'antd'
import './index.less'


@withRouter
@inject('Store')
@observer
class Loading extends Component {
    loadingCheck = null;
    constructor(props){
        super(props)
        this.state={
            loading:false,
        }
    }
    componentDidUpdate(){
        if(this.loadingCheck !== null) {clearTimeout(this.loadingCheck)}
        if(this.state.loading){
            this.loadingCheck = setTimeout(()=>{
                this.loadingCheck = null;
                if (this.state.loading) {
                    this.setState({loading:false})
                }
            },300)
        }
    }
    componentWillReceiveProps(nextProps){
        if (this.props.location.pathname !== nextProps.location.pathname){
            this.setState({loading:true});
        }
    }
    render() {
        let {loading} = this.state;
        return (
            <Spin tip='LOADING' wrapperClassName='Loading_wrap' spinning={loading}>
                {this.props.children}
            </Spin>
        )
    }
}

export default Loading
