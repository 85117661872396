import Loadable from 'react-loadable'
import DelayLoading from '../components/DelayLoading'

const MockExam = Loadable({loader: () => import('../views/Examination/MockExam/index'), loading : DelayLoading,delay:3000});
const Menu = Loadable({loader: () => import('../views/Examination/Menu/index'), loading : DelayLoading,delay:3000});
const Explain = Loadable({loader: () => import('../views/Examination/Explain/index'), loading : DelayLoading,delay:3000});
const Choice = Loadable({loader: () => import('../views/Examination/Choice/index'), loading : DelayLoading,delay:3000});
const StoryRetelling = Loadable({loader: () => import('../views/Examination/StoryRetelling/index'), loading : DelayLoading,delay:3000});
const ShortAnswer = Loadable({loader: () => import('../views/Examination/ShortAnswer/index'), loading : DelayLoading,delay:3000});
const Analog = Loadable({loader: () => import('../views/Examination/Analog/index'), loading : DelayLoading,delay:3000});
const Opi = Loadable({loader: () => import('../views/Examination/Opi/index'), loading : DelayLoading,delay:3000});
const EquipmentTesting = Loadable({loader: () => import('../views/Examination/EquipmentTesting/index'), loading : DelayLoading,delay:3000});
const UpdateComplete = Loadable({loader: () => import('../views/UpdateComplete/index'), loading : DelayLoading,delay:3000});
const scoreTable = Loadable({loader: () => import('../views/scoreTable/index'), loading : DelayLoading,delay:3000});
const TempPage = Loadable({loader: () => import('../views/TempPage/index'), loading : DelayLoading,delay:3000});



export default [
    // {
    //     path:'/',
    //     component:Menu,
    // },
    {
        path:'/Examination/MockExam',
        component:MockExam,
    },
    {
        path:'/',
        component:Menu,  
    },
    {
        path:'/Examination/explain',
        component:Explain,
    },
    {
        path:'/Examination/choice',
        component:Choice,
    },
    {
        path:'/Examination/storyRetelling',
        component:StoryRetelling,
    },
    {
        path:'/Examination/shortAnswer',
        component:ShortAnswer,
    },
    {
        path:'/Examination/analog',
        component:Analog,
    },
    {
        path:'/Examination/opi',
        component:Opi,
    },
    {
        path:'/Examination/EquipmentTesting',
        component:EquipmentTesting,
    },
    {
        path:'/Examination/UpdateComplete',
        component:UpdateComplete,
    },
    {
        path:'/Examination/scoreTable',
        component:scoreTable,
    },
    {
        path:'/Examination/TempPage',
        component:TempPage,
    },
]